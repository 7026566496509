<template>
  <div class="login">
    <div class="container">
      <app-card class="login__card">
        <h1 class="title login__title">Вхід</h1>
        <div class="login__form">
          <form @submit.prevent="logIn">
            <div
              class="login__form-item"
              v-for="item in loginData"
              :key="item.id"
            >
              <label for="" class="login__form-label">{{ item.label }}</label>
              <base-input :item="item" />
            </div>
            <error v-if="error" :error="error" />
            <base-btn>Ввійти</base-btn>
          </form>
        </div>
      </app-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginData: [
        {
          id: "login",
          label: "Логін",
          elType: "input",
          placeholder: "",
          model: "",
        },
        {
          id: "password",
          label: "Пароль",
          elType: "input",
          placeholder: "",
          model: "",
          type: "password",
        },
      ],
      error: null,
    };
  },
  methods: {
    logIn() {
      const loginInfo = {};
      this.loginData.map((el) => {
        loginInfo[el.id] = el.model;
      });
      localStorage.access_token = null;
      if (loginInfo.login.trim() && loginInfo.password.trim()) {
        this.$client
          .post("/doctor/login", loginInfo)
          .then((resp) => {
            localStorage.access_token = resp.data.access_token;
            localStorage.doctor = resp.data.fio;
            localStorage.patient = null;
            this.$router.push({ name: "Search" });
          })
          .catch(() => {
            this.error = "Перевірте дані для входу та повторіть спробу";
            setTimeout(() => {
              this.error = null;
              this.loginData.map((el) => (el.model = ""));
            }, 2000);
          });
      } else {
        this.error = "Введіть дані для входу";
        setTimeout(() => {
          this.error = null;
        }, 2000);
      }
    },
  },
  mounted() {
    localStorage.doctor = "";
  },
};
</script>

<style scoped lang="scss">
.login {
  margin-top: 17px;
  margin-bottom: 30px;
  &__card {
    padding-top: 20vh !important;
  }
  &__title {
    text-align: center;
    margin-bottom: 26px;
  }

  &__form {
    max-width: 386px;
    margin: 0 auto;
    padding: 0 10px;
    @media (max-width: 767px) {
      max-width: 350px;
    }
    &-item {
      margin-bottom: 16px;
    }
    &-label {
      display: block;
      margin-bottom: 8px;
      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
  }
}
</style>